import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';

import Hero from "../sections/home6/Hero";
import Cta from "../sections/home6/Cta";
import Content1 from "../sections/home6/Content1";
import Content2 from "../sections/home6/Content2";
import Content3 from "../sections/home6/Content3";
import Content4 from "../sections/home6/Content4";
import Screenshots from "../sections/home6/Screenshots";
import Promo from "../sections/home6/Promo";
import Features from "../sections/home5/ShortFeatures";


// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

import { Global } from '@emotion/core';
import {injectIntl, IntlContextConsumer} from "gatsby-plugin-intl"
import Helmet from 'react-helmet';
import { Fade } from 'react-reveal';

const IndexPage = ({intl, ...rest}) => {
  return (
    <StickyProvider>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <Helmet>
            <link rel="alternate" hrefLang="x-default" href="https://couponise.app/" />
            {languages.map(language => {
              if(language !== intl.defaultLocale) {
                return (
                  <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/`} />
                )
              }
            })}
          </Helmet>
        )}
      </IntlContextConsumer>

      <Global
        styles={theme => ({
          body: {
            fontFamily: theme.fonts.body,
          }
        })}
      />
      <Layout>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <SEO
              title={intl.formatMessage({ id: "title" })}
              lang={currentLocale}
              description={intl.formatMessage({ id: "hero_description" })}
            />
          )}
        </IntlContextConsumer>
        <Hero className="position-relative pt-25 pt-md-31 pt-lg-30 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />
        <Cta className="bg-purple-heart py-10" />
        <div className="container pt-23">
          <div className="row justify-content-center">
            <div className="col-12">
              <Fade up delay={500}>
                <div
                  className="text-center"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <h2>{intl.formatMessage({ id: "how_it_works" })}</h2>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <Content1 className="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <Content2 className="bg-default-6 pt-14 pt-md-19 pt-lg-34 pb-9 pb-md-14 pb-lg-34 overflow-hidden position-relative" />
        <Content3 className="pt-23 pt-md-25 pb-11 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <Content4 className="pt-23 pt-md-25 pt-lg-35 pb-9 pb-md-14 pb-lg-33 overflow-hidden position-relative" />
        <Screenshots className="bg-purple-heart pt-13 pb-13 pt-md-17 pb-md-19 pt-lg-25 pb-lg-26" />
        <Features className="bg-default-6 pt-13 pt-md-19 pt-lg-0 pb-14 pb-md-18 pb-lg-26 overflow-hidden" />
        <Promo />
      </Layout>
    </StickyProvider>
  );
};

export default injectIntl(IndexPage);
