import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="pt-lg-9">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="circle-60 bg-sunset text-white font-size-7">
                  <i className="fa fa-tachometer-alt" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="simple_quick" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="simple_quick_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="circle-60 bg-bluewood text-white font-size-7">
                  <i className="fa fa-chart-bar" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="detailed_analytics" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="detailed_analytics_text" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="circle-60 bg-buttercup text-white font-size-7">
                  <i className="fa fa-globe-americas" />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6"><FormattedMessage id="public_campaigns" /></h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    <FormattedMessage id="public_campaigns_text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center mt-15"
          >
            <Link to={'/features/'} className="btn btn-couponise btn-2 rounded-pill">
              <FormattedMessage id="explore_all_features" />
            </Link>
          </div>

        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content1;
