import React from "react";
import { Link } from "gatsby";
import { Fade } from 'react-reveal';
import { FormattedMessage } from "gatsby-plugin-intl"

import imgM from "../../assets/image/home-6/png/hero-mobile.png";
import imgYS from "../../assets/image/home-6/png/cirle-yellow-shape.png";
import apple from '../../assets/store-buttons/apple.svg';
import google from '../../assets/store-buttons/google.svg';

const Hero = ({ intl, className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container position-static">
        <div className="row align-items-center justify-content-center position-static hero">
          {/* Hero Image */}
          <div className="col-xl-6 col-lg-6 col-md-7 col-xs-8 position-static">
            <div className="l6-hero-image-group">
              <Fade bottom>
                <img
                  className="w-100"
                  src={imgM}
                  alt=""
                />
              </Fade>
              <div className="img-1">
                <Fade left>
                <img
                  className="w-100"
                  src={imgYS}
                  alt=""
                />
                </Fade>
              </div>
            </div>
          </div>
          {/* End Hero Image */}
          {/* Hero Content */}
          <div className="col-lg-6 col-md-9 col-sm-11">
            <Fade right>
            <div
              className="mt-8 mt-lg-0 pr-md-11 pr-lg-0 pr-xl-10"
            >
              <h1 className="font-size-13 mb-8 pr-sm-18 pr-md-8 pr-lg-0 line-height-84">
                <FormattedMessage id="hero_title" />
              </h1>
              <h2 className="font-size-7 mb-0 pr-sm-10 pr-md-10 pr-lg-0 pr-xl-18">
                <FormattedMessage id="hero_description" />
              </h2>
              <div className="mt-10">
                <a href="https://apps.apple.com/app/couponise/id1554931849" rel="noreferrer" target="_blank">
                  <img style={{ width: '200px', display: 'block' }} src={apple} alt={'apple store'} />
                </a>
                <a className="ml-md-5" href="https://play.google.com/store/apps/details?id=app.couponise.couponise_android" rel="noreferrer" target="_blank">
                  <img style={{ width: '200px', display: 'block' }} src={google} alt={'google play'} />
                </a>
              </div>
            </div>
            </Fade>
          </div>
          {/* End Hero Content */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
