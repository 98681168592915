import React from "react";
import { Fade } from 'react-reveal';
import { FormattedMessage } from "gatsby-plugin-intl"

import imgS from "../../assets/image/home-6/png/green-shape.png";
import TransparentPhone from "../../assets/image/home-6/png/mobile-frame.png";
import Step from "../../assets/image/gifs/step3_fr10_loss180.gif";

const Content3 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-start">
          <div className="col-lg-4 offset-lg-1 col-md-4 col-xs-5">
            <div className="l6-content-image-group-3 mr-md-0 mr-lg-15 mr-xl-12">
              <div className="img-1">
                <Fade down delay={800}>
                  <img className="w-100" src={imgS} alt="" />
                </Fade>
              </div>
              <div className="img-2" style={{ position: 'relative'}}>
                <Fade right delay={500}>
                  <img className="w-100" src={Step} alt="step 3 couponise campaign creation" style={{ position: 'absolute', padding: '0 10px', top: '15px', borderRadius: '30px', overflow: 'hidden', height: '95%' }} />
                  <img className="w-100" src={TransparentPhone}  />
                </Fade>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-8 col-xs-10 ml-xl-19">
            <div className="pt-5 pb-7 pr-sm-5 pr-md-0 ml-md-10 ml-xl-0">
              <Fade up delay={300}>
                <h3 className="font-size-11 mb-9 pr-sm-10 pr-md-17 pr-lg-15 pr-xl-0 mt-lg-31">
                  3.
                  <br />
                  <FormattedMessage id="validate" />
                </h3>
              </Fade>
              <Fade up delay={600}>
                <p className="font-size-7 mb-0 pr-md-20 pr-xl-22">
                  <FormattedMessage id="validate_text" />
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content3;
