import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl"

const Cta = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div
              className="text-center"
            >
              {/*<a
                href="/#"
                className="video-btn text-white font-size-6 mb-0"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >*/}
              <span className="text-golden-yellow mr-3"><i className="fa fa-info-circle" /></span>
              {" "}<span className="text-white"><FormattedMessage id="cta_title" /></span>{" "}
              <span className="text-golden-yellow ml-3"><i className="fa fa-info-circle" /></span>
              {/*</a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
