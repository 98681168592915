import React from "react";
import { Link } from "gatsby";
import { FormattedMessage } from 'gatsby-plugin-intl';

import apple from '../../assets/store-buttons/apple.svg';
import google from '../../assets/store-buttons/google.svg';


const Promo = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="pt-13 pt-md-18 pt-lg-21 pb-13 pb-md-17 pb-lg-22">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div className="text-center text-lg-left mb-13 mb-lg-0">
                <h3 className="font-size-9 mb-7">
                  <FormattedMessage id="download_our_app" />
                </h3>
                <p className="font-size-6 mb-0 ">
                  <FormattedMessage id="download_our_app_text" />
                </p>
              </div>
            </div>
            <div className="col-lg-5 offset-xl-1 col-md-8 col-sm-10">
              <div className="text-center text-lg-left">
                <h5 className="font-size-6 text-oxford-blue opacity-7 font-weight-normal mb-7 ml-lg-6">
                  <FormattedMessage id="available_on" />
                </h5>
                <div className="mt-10">
                  <a href="https://apps.apple.com/app/couponise/id1554931849" rel="noreferrer" target="_blank">
                    <img style={{ width: '200px', display: 'block' }} src={apple} alt={'apple store'} />
                  </a>
                  <a className="ml-md-5" href="https://play.google.com/store/apps/details?id=app.couponise.couponise_android" rel="noreferrer" target="_blank">
                    <img style={{ width: '200px', display: 'block' }} src={google} alt={'google play'} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
