import React from "react";
import { Fade } from 'react-reveal';
import { FormattedMessage } from "gatsby-plugin-intl"

import imgS from "../../assets/image/home-6/png/yellow-small-shape.png";
import TransparentPhone from "../../assets/image/home-6/png/mobile-frame.png";
import Step from "../../assets/image/gifs/step2_fr12_loss150.gif";


const Content2 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-start">
          {/* Content Section */}
          <div className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-md-1">
            <div className="pt-7 pb-7 pr-sm-5 pr-md-9 pr-lg-0">
              <Fade up delay={300}>
                <h3 className="font-size-11 mb-9 pr-sm-18 pr-md-10 pr-lg-5 pr-xl-5">
                  2.
                  <br />
                  <FormattedMessage id="invite_members" />
                </h3>
              </Fade>
              <Fade up delay={600}>
                <p className="font-size-7 mb-0">
                  <FormattedMessage id="invite_members_text" />
                </p>
              </Fade>
            </div>
          </div>
          {/* Content Image Section */}
          <div className="col-xl-6 offset-xl-1 col-lg-6 col-xs-5 order-1 order-md-2">
            <div className="l6-content-image-group-2 ml-lg-18">
              <div className="img-1">
                <Fade up delay={700}>
                  <img className="w-100" src={imgS} alt="" />
                </Fade>
              </div>
              <div className="img-2" style={{ position: 'relative'}}>
                <Fade right delay={500}>
                  <img className="w-100" src={Step} alt="step2 couponise campaign creation" style={{ position: 'absolute', padding: '0 10px', top: '15px', borderRadius: '30px', height: '95%' }} />
                  <img className="w-100" src={TransparentPhone} alt="iphone frame" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
