import React from "react";
import { Fade } from 'react-reveal';
import { FormattedMessage } from "gatsby-plugin-intl"

import imgG from "../../assets/image/home-6/png/green-dot-pattern.png";
import TransparentPhone from "../../assets/image/home-6/png/mobile-frame.png";
import Step from "../../assets/image/gifs/step1_fr10_loss_180.gif";


const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-lg-4 offset-lg-1 col-md-4 col-xs-5">
            <div className="l6-content-image-group">
              <div className="img-1">
                <Fade down delay={800}>
                  <img className="w-100" src={imgG} alt="" />
                </Fade>
              </div>

              <div className="img-2" style={{ position: 'relative'}}>
                <Fade right delay={500}>
                  <img className="w-100" src={Step} alt="step 1 couponise campaign creation" style={{ position: 'absolute', padding: '0 10px', top: '15px', borderRadius: '30px', overflow: 'hidden', height: '95%' }} />
                  <img className="w-100" src={TransparentPhone}  />
                </Fade>
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-6 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
            <div className="pt-7 pb-7 pb-lg-17 pl-sm-5 pl-md-9 pl-lg-10">
              <Fade up delay={300}>
                <h3 className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18">
                  1.
                  <br />
                  <FormattedMessage id="create_a_campaign"></FormattedMessage>
                </h3>
              </Fade>
              <Fade up delay={600}>
                <p className="font-size-7 mb-0 pr-xl-13">
                  <FormattedMessage id="create_a_campaign_text"></FormattedMessage>
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
